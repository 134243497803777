/** @format */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  clone,
  concat,
  each,
  find,
  get,
  head,
  isEqual,
  isNil,
  join,
  keys,
  last,
  filter as lfilter,
  map,
  merge,
  size,
  uniqBy,
  values,
} from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  EquipmentDocumentKind,
  EquipmentKind,
  EquipmentKindCategory,
  EquipmentKindFireSafetyCategoryName,
  EquipmentKindMetadataKind,
  Family,
  InspectionStateOptions,
  IPageTrackingInfo,
} from 'sesio-lib';
import { SubSink } from 'subsink';
import { convertHeaderSelection, IHeaderSearch } from '../../../_classes/header-search';
import { EquipmentKindService } from '../../../_services/equipment-kind.service';
import { EquipmentService } from '../../../_services/equipment.service';
import { IHeaderSelection, SessionService } from '../../../_services/session.service';
import { ContractorModal } from '../../contractor/contractor.modal/contractor.modal';
import { ColumnOption } from '../../datagrid/column-def.class';
import { DatagridComponent, IOptions } from '../../datagrid/datagrid.component';
import { IRecord } from '../../datagrid/datasource';
import { IDatatableOptions, IDatatableRecords } from '../../datagrid/datatable.class';
import { DocumentModal } from '../../document/document.modal/document.modal';
import { EquipmentDatagridEditModal } from './edit-modal/equipment-datagrid-edit.modal';

type Column = string | ColumnOption;
@Component({
  selector: 'app-equipment-datagrid',
  templateUrl: './equipment-datagrid.component.html',
  styleUrls: ['./equipment-datagrid.component.scss'],
})
export class EquipmentDatagridComponent implements OnInit, OnDestroy {
  public tracking: IPageTrackingInfo;
  public rowEdit: boolean;
  public enableStickyColumn: boolean;
  public style: any;
  public families: Family[];
  public categories: EquipmentKindCategory[];
  public kindId: string;
  public kind: EquipmentKind;
  public kinds: EquipmentKind[];
  public options: IOptions;
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  public datagrid: DatagridComponent;

  private headerFilterLinked: boolean;
  private columns: Column[];
  private administration = false;
  private modal: HTMLIonModalElement;
  private filter: IHeaderSearch;
  private loaded = false;
  private subsink = new SubSink();

  constructor(
    public equipmentService: EquipmentService,
    public equipmentKindService: EquipmentKindService,
    private translate: TranslateService,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.route.paramMap.subscribe(params => {
        this.kindId = params.has('kind') ? params.get('kind') : null;
      }),
      this.route.data.subscribe(async data => {
        this.style = get(data, 'style', { height: 'calc(100vh - 194px)' });
        this.administration = get(data, 'administration', false);
        this.rowEdit = get(data, 'rowEdit', true);
        this.enableStickyColumn = get(data, 'enableStickyColumn', false);
        this.tracking = get(data, 'tracking', null);
        this.headerFilterLinked = get(data, 'headerFilterLinked', false);
        this.families = get(data, 'families', values(Family));
        this.categories = get(data, 'categories', values(EquipmentKindCategory));
        this.kinds = get(data, 'kinds', []);
        if (this.kindId) {
          this.kind = await this.equipmentKindService.get(this.kindId).toPromise();
          if (this.tracking && this.kind) this.tracking.page += ` > ${this.kind.name}`;
          this.kinds = uniqBy(concat(this.kinds, [this.kind]), 'id');
        }
        this.columns = get(data, 'columns', [
          'reference',
          'organizationalUnit.pathNames',
          'realEstateStructure.pathNames',
          'address',
          'location',
          'label',
          'supervisionStatus',
          'contractors.maintenance',
          'contractStartDate',
          'contractEndDate',
          'kind.category',
          'kind.name',
          'archived',
        ]);
        if (this.kindId && data.columnsByCategory)
          this.columns = get(data, `columnsByCategory.${this.kind.category}`, this.columns);
        this.loadOptions();
      }),
      this.sessionService.$headerSelection
        .pipe(filter(() => this.headerFilterLinked))
        .subscribe(data => this.loadFilter(data))
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public async edit(datagrid: DatagridComponent, record?: IRecord): Promise<void> {
    if (this.modal) return;
    this.modal = await this.modalController.create({
      component: EquipmentDatagridEditModal,
      cssClass: 'xwide-modal',
      componentProps: {
        administration: this.administration,
        id: record?._id,
        families: this.families,
        categories: this.categories,
        kinds: this.kinds,
        organizationalUnit:
          this.headerFilterLinked && this.filter.organizationalUnit ? this.filter.organizationalUnit : undefined,
      },
    });
    await this.modal.present();
    this.modal.onWillDismiss().then(() => (this.modal = null));
    const res = await this.modal.onDidDismiss();
    if (res.data) datagrid.loadPage();
  }

  public loadDatagrid() {
    this.datagrid.loadPage();
    this.loaded = true;
  }

  public loadFilter(selection?: IHeaderSelection): void {
    const filter: any = convertHeaderSelection(selection);
    if (isEqual(this.filter, filter)) return;
    this.filter = filter;
    if (this.loaded) this.datagrid.loadPage();
  }

  private loadOptions() {
    this.options = {
      pagination: { default: 2, options: [10, 20, 50, 100] },
      service: (options: IDatatableOptions): Observable<IDatatableRecords<IRecord>> =>
        this.equipmentService.datatable(
          options,
          merge(
            { families: this.families, categories: this.categories, equipmentKindIds: map(this.kinds, 'id') },
            this.filter
          ),
          null,
          null,
          this.administration
        ),
      columns: this.buildColumn(),
      actions: [
        {
          icon: { name: 'add_circle_outline' },
          exec: datagrid => this.edit(datagrid),
        },
      ],
      loadOnDisplay: false,
      enableRowNumber: false,
      enableStickyColumn: this.enableStickyColumn,
      enableReorderColumn: true,
      enableSelect: false,
      enableHideShowColumns: true,
      enableExport: true,
      enableFullscreen: false,
      disableScrollbarModule: false,
      rowClick: this.rowEdit ? (datagrid: DatagridComponent, record: IRecord) => this.edit(datagrid, record) : null,
      rowColor: (record: any) => {
        return this.administration && record.archived ? 'var(--ion-color-warn)' : undefined;
      },
      rowTooltip: (record: any) => {
        return this.administration && record.archived
          ? `L'equipement ${record.reference} a été archivé le ${
              record.archivedChangedAt ? moment(record.archivedChangedAt).format('L LT') : 'ND'
            } par ${record.archivedChangedBy?.email}`
          : undefined;
      },
    };
    if (!!find(this.options.columns, { property: 'inspectionSate' })) {
      this.options.rowColor = (record: any) => InspectionStateOptions[record?.inspectionSate]?.color;
    }
    if (this.families?.length > 1) {
      this.options.columns.splice(4, 0, {
        property: 'kind.family',
        label: 'family',
        type: 'select',
        searchable: true,
        sortable: true,
        options: map(this.families, c => ({
          value: c,
          name: this.translate.instant(c),
        })),
      });
    }
  }

  private buildColumn(): ColumnOption[] {
    let columns = [];
    const columnDefs = this.getColumnDefs();
    if (!this.columns.length) return columnDefs;
    each(this.columns, column => {
      if (typeof column !== 'string') {
        const columnDef = find(columnDefs, { property: column.property });
        columns.push(merge(clone(columnDef || {}), column));
      } else {
        const columnDef = find(columnDefs, { property: column });
        if (columnDef) columns.push(columnDef);
      }
    });
    if (!this.columns.includes('archived')) {
      columns = concat(columns, [
        { hidden: true, property: 'archived' },
        { hidden: true, property: 'archivedChangedAt' },
        { hidden: true, property: 'archivedChangedBy' },
      ]);
    }
    return columns;
  }

  private getColumnDefs(): ColumnOption[] {
    return [
      {
        property: 'reference',
        label: 'reference',
        searchable: true,
        sortable: true,
        color: 'var(--ion-color-secondary)',
        click: record => {
          if (!this.rowEdit) this.edit(this.datagrid, record);
        },
      },
      {
        label: 'group',
        property: 'organizationalUnit.pathNames',
        width: 350,
        content: 'group',
        splitExport: (record: any) => [
          {
            name: 'DT',
            value: get(record, 'organizationalUnit.pathNames.1'),
          },
          {
            name: 'Agence',
            value: get(record, 'organizationalUnit.pathNames.2'),
          },
          {
            name: 'Groupe',
            value: get(record, 'organizationalUnit.pathNames.3'),
          },
        ],
        sortable: true,
        searchable: true,
      },
      {
        label: 'ESI',
        property: 'realEstateStructure.pathNames',
        width: 350,
        content: 'realEstateStructure',
        splitExport: (record: any) => [
          {
            name: 'ESI path',
            value: join(get(record, 'organizationalUnit.pathNames'), ' > '),
          },
          {
            name: 'ESI',
            value: last(get(record, 'organizationalUnit.pathNames')),
          },
        ],
        sortable: true,
        searchable: true,
      },
      {
        label: 'ESI',
        property: 'realEstateStructureId',
        searchProperty: 'realEstateStructureId.reference',
        linkedProperties: [
          'realEstateStructureId._id',
          'realEstateStructureId.reference',
          'realEstateStructureId.kind',
        ],
        width: 250,
        content: 'realEstateStructure',
        contentExport: (column, record: any) =>
          `${record?.realEstateStructureId?.kind} - ${record?.realEstateStructureId?.reference}`,
        sortable: true,
        searchable: true,
      },
      {
        tooltip: `Adresse du groupe`,
        label: 'Adresse',
        searchable: true,
        sortable: true,
        property: 'address',
        type: 'text',
      },
      {
        tooltip: `Localisation de l'équipement`,
        label: 'Localisation',
        searchable: true,
        sortable: true,
        property: 'location',
        type: 'text',
      },
      {
        label: 'label',
        property: 'label',
        searchable: true,
        sortable: true,
      },
      {
        property: 'supervised',
        label: 'Supervision',
        tooltip: `Supervision de l'équipement`,
        type: 'checkbox',
        searchable: true,
        sortable: true,
      },
      {
        property: 'contractors.maintenance',
        label: 'Prestataire',
        displayProperty: 'contractors.maintenance.name',
        searchProperty: 'contractors.maintenance.name',
        linkedProperties: ['contractors.maintenance._id', 'contractors.maintenance.name'],
        color: 'var(--ion-color-secondary)',
        searchable: true,
        sortable: true,
        click: async (record: any) => {
          const modal = await this.modalController.create({
            component: ContractorModal,
            cssClass: 'xwide-modal',
            componentProps: {
              eqId: record._id,
              id: record.contractors.maintenance._id,
              name: record.contractors.maintenance.name,
              tracking: merge(this.tracking, { id: record?._id }),
            },
          });
          await modal.present();
        },
      },
      {
        label:
          this.families.length === 1 && head(this.families) === Family.RELAY_ANTENNA
            ? 'Date début de convention cadre'
            : 'Date début de contrat',
        tooltip:
          this.families.length === 1 && head(this.families) === Family.RELAY_ANTENNA
            ? 'Date début de convention cadre'
            : 'Date début de contrat',
        sortable: true,
        searchable: true,
        property: 'contractStartDate',
        type: 'date',
      },
      {
        label:
          this.families.length === 1 && head(this.families) === Family.RELAY_ANTENNA
            ? 'Date fin de convention cadre'
            : 'Date fin de contrat',
        tooltip:
          this.families.length === 1 && head(this.families) === Family.RELAY_ANTENNA
            ? 'Date fin de convention cadre'
            : 'Date fin de contrat',
        sortable: true,
        searchable: true,
        property: 'contractEndDate',
        type: 'date',
      },
      {
        label: 'Date de début de convention',
        tooltip: `Date de début de convention`,
        sortable: true,
        searchable: true,
        property: 'metadata.conventionStartDate',
        type: 'date',
      },
      {
        label: 'Date de fin de convention',
        tooltip: `Date de fin de convention`,
        sortable: true,
        searchable: true,
        property: 'metadata.conventionEndDate',
        type: 'date',
      },
      {
        property: 'kind.category',
        label: 'category',
        type: 'select',
        searchable: true,
        sortable: true,
        options: map(this.categories, c => ({
          value: c,
          name: EquipmentKindFireSafetyCategoryName[c] || this.translate.instant(c),
        })),
      },
      {
        property: 'kind.name',
        label: 'kind',
        searchable: true,
        sortable: true,
      },
      {
        property: 'anomalyCount',
        label: "Nombre d'anomalies",
        type: 'number',
        displayWith: (record: any) => (isNil(record.anomalyCount) ? 0 : record.anomalyCount),
        sortable: true,
        searchable: true,
      },
      {
        property: 'dangerCount',
        label: 'Nombre de danger',
        type: 'number',
        displayWith: (record: any) => (isNil(record.dangerCount) ? 0 : record.dangerCount),
        sortable: true,
        searchable: true,
      },
      {
        property: 'inspectionSate',
        label: 'État',
        type: 'select',
        options: map(keys(InspectionStateOptions), k => ({
          value: k,
          icon: {
            name: InspectionStateOptions[k]?.icon,
            color: InspectionStateOptions[k]?.color,
          },
        })),
        multiple: true,
        searchable: true,
        width: 50,
      },
      {
        property: 'lastInspection',
        label: 'Dernière VR',
        content: 'lastInspection',
        searchProperty: 'lastInspection.reference',
        linkedProperties: ['lastInspection.reference', 'lastInspection.reportDate', 'lastInspection.reportStatus'],
        contentExport: (column, record: any) => record?.lastInspection?.reference,
        searchable: true,
        sortable: true,
      },
      {
        property: 'nextInspection',
        label: 'VR liée',
        content: 'nextInspection',
        searchProperty: 'nextInspection.reference',
        linkedProperties: ['nextInspection.reference', 'nextInspection.activeAt', 'nextInspection.deadline'],
        contentExport: (column, record: any) => record?.nextInspection?.reference,
        searchable: true,
        sortable: true,
      },
      {
        label: 'Archivé',
        property: 'archived',
        linkedProperties: ['archived', 'archivedChangedAt', 'archivedChangedBy'],
        type: 'checkbox',
        searchable: true,
        sortable: true,
      },
      ...this.getFilesColumns(),
      ...this.getMetadataColumns(),
    ] as ColumnOption[];
  }

  private getFilesColumns(): ColumnOption[] {
    const columns: ColumnOption[] = [];
    each(values(EquipmentDocumentKind), kind => {
      columns.push({
        label: this.translate.instant(`equipment-file.${kind}`),
        property: `files.${kind}`,
        displayProperty: 'files',
        click: (record: any) => this.openFileModal(record, kind),
        content: 'files',
        contentExport: (column, record: any) => size(lfilter(record?.files, f => f.kind === kind)).toString(),
        metadata: { kind: kind },
      });
    });
    return columns;
  }

  private getMetadataColumns(): ColumnOption[] {
    const columns: ColumnOption[] = [];
    each(get(this.kind, 'metadata'), metadata => {
      let column: ColumnOption;
      switch (metadata.kind) {
        case EquipmentKindMetadataKind.STRING_ENUM:
          column = {
            type: 'select',
            label: metadata.label,
            property: `metadata.${metadata.name}`,
            options: map(metadata.options, o => ({ value: o.value, name: o.label })),
            searchable: true,
            sortable: true,
          };
          break;
        case EquipmentKindMetadataKind.BOOLEAN:
          column = {
            type: 'checkbox',
            label: metadata.label,
            color: record => (get(record, `metadata.${metadata.name}`) ? 'var(--ion-color-success)' : null),
            property: `metadata.${metadata.name}`,
            searchable: true,
            sortable: true,
          };
          break;
        case EquipmentKindMetadataKind.DATE:
          column = {
            type: 'date',
            label: metadata.label,
            format: 'L',
            property: `metadata.${metadata.name}`,
            searchable: true,
            sortable: true,
          };
          break;
        case EquipmentKindMetadataKind.CONTRACTOR:
          column = {
            label: metadata.label,
            property: `metadata.${metadata.name}`,
            searchProperty: `metadata.${metadata.name}.name`,
            displayProperty: `metadata.${metadata.name}.name`,
            searchable: true,
            sortable: true,
          };
          break;
        default:
          column = {
            label: metadata.label,
            translateValue: true,
            property: `metadata.${metadata.name}`,
            searchable: true,
            sortable: true,
          };
      }
      if (column) columns.push(column);
    });
    return columns;
  }

  private async openFileModal(record: any, kind: EquipmentDocumentKind): Promise<void> {
    if (
      !get(
        lfilter(record?.files, f => f.kind === kind),
        'length',
        0
      )
    ) {
      return;
    }
    const modal = await this.modalController.create({
      component: DocumentModal,
      cssClass: 'xwide-modal',
      componentProps: { id: record._id, name: record.reference, kind },
    });
    await modal.present();
  }
}
