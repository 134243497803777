/** @format */

export const IntentKeyName = {
  abnormal_use: 'Usage anormal',
  adaptive: "Maintien pour que l'appareil soit adapté à son environnement, réglages, ...",
  'closed-dismissed': "refus de l'intervention réalisée par le demandeur",
  'closed-done': 'clôture "normale" de l\'intervention par le prestataire',
  'closed-formal_notice': "clôture de l'intervention avec notification formelle (LAR, etc.)",
  'closed-occupant_absent': "Clôture de l'intervention pour occupant absent",
  'closed-occupant_denial': "Clôture de l'intervention pour refus de l'occupant",
  'closed-partial_repair':
    "clôture de l'intervention avec notification de réparation partielle ou solution de contournement",
  'closed-solved': "acceptation de l'intervention réalisée par le demandeur",
  'closed-temporary_repair': "clôture de l'intervention avec notification de réparation temporaire",
  corrective: 'Maintien curatif, dépannages, réparations ...',
  'denied-canceled': 'refus sans motif',
  'denied-non_contractual': 'refus parce que hors contrat (pour contrats forfaitaires)',
  'denied-unable': "refus pour impossibilité d'intervenir",
  diagnosis: "Diagnostic de l'état de santé d'un équipement",
  energetic_coaching: "Coaching énergétique des occupants d'un logement",
  external_reason: 'Cause extérieure (inondation...)',
  false_alert: 'Fausse alerte',
  'hold-extension_request': "demande effectuée pour une extension (hors forfait) du périmètre de l'intervention",
  'hold-missing_item': 'pièce manquante pour effectuer la réparation',
  'hold-occupant_absent': "\"remise à plus tard\" d'une intervention pour cause d'absence de l'occupant",
  'hold-occupant_denial': '"remise à plus tard" d\'une intervention pour cause de refus de l\'occupant',
  'hold-partial_repair':
    "remise à plus tard de l'intervention suite à réparation partielle ou solution de contournement",
  'hold-postponed': "remise à plus tard de l'intervention à l'initiative du prestataire (sans motif supplémentaire)",
  'hold-quote_request':
    'demande / proposition de devis pour une intervention hors forfait / pour des pièces nécessaires',
  'hold-safety_issue':
    "\"remise à plus tard\" d'une intervention à cause d'un problème de sécurité affectant les conditions d'intervention",
  'hold-technical_issue': '"remise à plus tard" d\'une interventon à cause d\'un problème technique',
  'hold-temporary_repair': "remise à plus tard de l'intervention suite à une réparation temporaire",
  none: 'Aucun',
  'open-acknowledged': 'le prestataire "accuse réception" de la demande (ou état initial d\'une interv)',
  'open-client_planned': "planification à l'initiative du locataire/occupant",
  'open-due': 'intervention à échéance (date anniversaire)',
  'open-planned': "planification de l'intervention",
  'open-requested': "intervention demandée, à l'initiative du gestionnaire ou du prestataire",
  operational: "Maintien de l'appareil en condition d'utilisation, entretien, nettoyage ...",
  other_pm: 'Autre cause imputable au client',
  other_sp: 'Autre cause imputable au prestataire',
  'pending-end': "date de fin d'intervention",
  'pending-on_site': "date d'arrivée du technicien sur site",
  'pending-replanned': "replanification succédant à une intervention ou une tentative d'intervention",
  'pending-start': "date de début d'intervention",
  power_failure: 'Panne électrique',
  preventive: "Maintien préventif, pour prévenir les pannes = visites d'entretien, visites de sécurité, audit ...",
  regulatory: 'Intervention réglementaire effectuée par un bureau de contrôle',
  renovation: 'Travaux de rénovation',
  setup: 'Mise en marche / arrêt',
  technical_failure: 'Panne technique',
  time_worn: 'Matériel âgé',
  undefined: '-',
  vandalism: 'Vandalisme',
  work_order: 'Bon de commande',
  people_trapped: 'Usager bloqué',
};

export const IntentEquipmentStatusName = {
  bad_condition: 'Mauvaise condition',
  ok: 'Ok',
  new: 'Nouveau',
};

export const IntentStatusName = {
  closed: 'Clôturé',
  hold: 'Suspendue',
  open: 'Ouverte',
  pending: 'En attente',
  active: 'Active',
  denied: 'Refusée',
};
